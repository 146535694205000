<script setup lang="ts">
import {ref, inject} from "vue";
import type {FrostflakeAPI} from "@/api";
import {useLoadingBar, useMessage} from 'naive-ui';
import {useRouter} from 'vue-router';
import {ApiException} from "@/api";

let api = inject('api') as FrostflakeAPI;
const loadingBar = useLoadingBar();
const message = useMessage();
const router = useRouter();

let token = ref("");
let disabled = ref(false);

async function testToken() {
  api.setMasterPassword(token.value);
  loadingBar.start();

  api.checkPassword().then(() => {
    loadingBar.finish();
    router.push({path: '/dashboard'});
  }).catch(e => {
    loadingBar.error();
    if (e instanceof ApiException) {
      message.error("Invalid master password");
    } else {
      message.error("Login failed, network error");
    }
  })
}
</script>

<template>
  <div class="h-full flex flex-col place-items-center justify-center">
    <n-card title="Authentication" hoverable style="max-width: 512px;">
      <n-space vertical>
        <n-input type="password" show-password-on="mousedown" placeholder="Master password" v-model:value="token"
                 :disabled="disabled"/>
        <div class="flex justify-end">
          <n-button type="primary" size="small" @click="testToken" :disabled="disabled">
            Login
          </n-button>
        </div>
      </n-space>
    </n-card>
  </div>
</template>
