import type {Application, RegisterToken, User} from "@/worker/licenseManager";
import type {ApiResp, UserInfo} from "@/worker/types";
import axios, {type AxiosResponse, type InternalAxiosRequestConfig} from "axios";

export class ApiException extends Error {
    constructor(public code: number, public message: string) {
        super(message);
    }
}

export class NetworkException extends Error {
    constructor(public message: string) {
        super(message);
    }
}

const client = axios.create({
    baseURL: window.location.origin + "/manage",
    timeout: 30000
});

let masterPassword: string | null = null;

client.interceptors.response.use((response: AxiosResponse) => {
    if (response && response.data) {
        const resp = response.data as ApiResp<any>;
        if (resp.code !== 0) {
            console.error(resp);
            throw new ApiException(resp.code, resp.message);
        }
        return resp.data;
    }
    return response;
}, (error: any) => {
    console.error(error.toJSON());
    throw new NetworkException(error.toJSON());
});

client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        if (!!masterPassword)
            config.headers['Frostflake-Authorization'] = masterPassword;
        return config;
    }, (error: any) => {
        return Promise.reject(error);
    }
);

function setMasterPassword(password: string) {
    masterPassword = password;
}

async function checkPassword() {
    return await client.post("/checkPassword", {}) as null;
}

async function listApplications() {
    return await client.post("/applications/list", {}) as Application[];
}

async function saveApplication(application: Application) {
    return await client.post("/applications/save", application) as null;
}

async function deleteApplication(id: number) {
    return await client.post("/applications/delete", {id}) as null;
}

async function listUsers() {
    return await client.post("/users/list", {}) as UserInfo[];
}

async function saveUser(user: User) {
    return await client.post("/users/save", user) as null;
}

async function deleteUser(id: number) {
    return await client.post("/users/delete", {id}) as null;
}

async function issueToken(user_id: number, application_id: number) {
    return await client.post("/token/issue", {user_id, application_id}) as string;
}

async function listToken(user_id: number) {
    return await client.post("/token/list", {user_id}) as RegisterToken[];
}

async function revokeToken(token: string) {
    return await client.post("/token/revoke", {token}) as null;
}

async function activateDevice(id: number) {
    return await client.post("/hardwares/activate", {id}) as null;
}

async function revokeDevice(id: number) {
    return await client.post("/hardwares/revoke", {id}) as null;
}

async function banDevice(id: number) {
    return await client.post("/hardwares/ban", {id}) as null;
}

export interface FrostflakeAPI {
    setMasterPassword: (password: string) => void;
    isMasterPasswordSet: () => boolean;
    checkPassword: () => Promise<null>;
    applications: {
        list: () => Promise<Application[]>;
        save: (application: Application) => Promise<null>;
        delete: (id: number) => Promise<null>;
    },
    users: {
        list: () => Promise<UserInfo[]>;
        save: (user: User) => Promise<null>;
        delete: (id: number) => Promise<null>;
    },
    tokens: {
        issue: (user_id: number, application_id: number) => Promise<string>;
        list: (user_id: number) => Promise<RegisterToken[]>;
        revoke: (token: string) => Promise<null>;
    },
    hardwares: {
        activate: (id: number) => Promise<null>;
        revoke: (id: number) => Promise<null>;
        ban: (id: number) => Promise<null>;
    }
}

export function createApi(): FrostflakeAPI {
    return {
        setMasterPassword,
        isMasterPasswordSet: () => !!masterPassword,
        checkPassword,
        applications: {
            list: listApplications,
            save: saveApplication,
            delete: deleteApplication
        },
        users: {
            list: listUsers,
            save: saveUser,
            delete: deleteUser
        },
        tokens: {
            issue: issueToken,
            list: listToken,
            revoke: revokeToken
        },
        hardwares: {
            activate: activateDevice,
            revoke: revokeDevice,
            ban: banDevice
        }
    }
}