<script setup lang="ts">
import Certificate from '@vicons/carbon/Certificate'
import {type Ref, ref} from "vue";
import {RouterView} from 'vue-router'

let overlayRef: Ref<undefined | HTMLElement> = ref(undefined);
</script>

<template>
  <n-dialog-provider>
    <n-loading-bar-provider :to="overlayRef" container-class="overlay-override">
      <n-message-provider :to="overlayRef">
        <div class="px-5 py-3 bg-white border-b-1 border-gray-300">
          <n-page-header>
            <template #title>
              <div class="ms-1">
                <div class="font-bold">Frostflake</div>
                <div class="font-thin text-xs">Application License Management</div>
              </div>
            </template>
            <template #avatar>
              <div class="flex place-items-center relative">
                <n-avatar color="transparent" src="./android-chrome-512x512.png"  size="large"/>
              </div>
            </template>
          </n-page-header>
        </div>
        <div ref="overlayRef"></div>
        <div class="page-content overflow-y-auto h-full p-5">
          <div class="">
            <RouterView/>
          </div>
        </div>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-dialog-provider>
</template>

<style scoped>
.logo-overlay {
  position: absolute;
  top: 4px;
  left: 4px;
}

.page-content {
  height: calc(100vh - .75em - .75em - 47px);
}
</style>
